import {
  Flex,
  Text,
} from '@chakra-ui/react';

const FeedbackButton = (params: {
  index: number;
  wordspaceNumber: string;
  orientation: 'down' | 'across';
  handleClick: () => void;
  disabled: boolean;
  plaintext: string;
  clue: string;
}) => {
  const { index, wordspaceNumber, handleClick, disabled, plaintext, clue } = params;

  return (
    <Flex key={index} w="100%" justifyContent="space-between">
      <Text
        sx={{
          borderRadius: '0.375rem',
          width: '100%',
          padding: '5px',
          textAlign: 'start',
          justifyContent: 'flex-start',
          textWrap: 'wrap',
          color: disabled ? '#81E6D9' : 'black',
          backgroundColor: disabled ? '#EDF2F7' : '#fff',
        }}
        _hover={{
          cursor: disabled ? 'not-allowed' : 'pointer',
          backgroundColor: !disabled && '#EDF2F7',
        }}
        onClick={() => {
          if (disabled) return;

          handleClick();
        }}
      >
        {wordspaceNumber}. {plaintext} - {clue}
      </Text>
    </Flex>
  );
};

export default FeedbackButton;
