import { Feedback } from './Interfaces';

// PROD can be found at: https://prod-api-421014.ue.r.appspot.com/
const host = process.env.NODE_ENV === 'development' ?  'http://localhost:8123' : 'https://prod-api-421014.ue.r.appspot.com';

export const apiClient = {
  postXword: async () => {
    return await fetch(`${host}/xword?random=true`, {
      method: 'POST',
    });
  },
  getLatestXword: async () => { 
    return await fetch(`${host}/xword/latest`);
  },
  getXword: async (uuid: string, isDaily: boolean = true) => {
    return await fetch(`${host}/xword?xwordId=${uuid}&isDaily=${isDaily}`);
  },
  postFeedback: async (feedbacks: Array<Feedback>) => {
    return await fetch(`${host}/feedback`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(feedbacks),
    });
  },
};
