import React, { useState } from 'react';
import {
  Checkbox,
  Button,
  Heading,
  FormLabel,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  VStack,
  Select,
} from '@chakra-ui/react';
import { Feedback } from './Interfaces';
import { apiClient } from './apiClient';

interface WordspaceFeedbackModalParams {
  wordspaceNumber: string;
  plaintext: string;
  clueText: string;
  clueId: string;
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  markFeedbackProvided: (wordspaceNumber: string, clueId: string) => void;
}

const WordspaceFeedbackModal = ({
  wordspaceNumber,
  clueId,
  showModal,
  setShowModal,
  markFeedbackProvided,
}: WordspaceFeedbackModalParams) => {
  const [clueIncorrect, setClueIncorrect] = useState(false);
  const [clueProfanity, setClueProfanity] = useState(false);
  const [wordspaceProfanity, setWordspaceProfanity] = useState(false);
  const [difficulty, setDifficulty] = useState('medium');

  return (
    <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
      <ModalOverlay bg="grey" />;
      <ModalContent maxW="450px" p="20px">
        <ModalCloseButton onClick={() => setShowModal(false)} />
        <Heading fontSize="20px" fontWeight="bold" color="black" sx={{ marginBottom: '20px' }}>
          Provide Feedback
        </Heading>
        <VStack spacing={5} direction="column" align="left">
          <Checkbox onChange={() => setClueIncorrect(!clueIncorrect)} checked={clueIncorrect}>
            Clue Incorrect
          </Checkbox>
          <Checkbox onChange={() => setClueProfanity(!clueProfanity)} checked={clueProfanity}>
            Clue Contains Profanity
          </Checkbox>
          <Checkbox onChange={() => setWordspaceProfanity(!wordspaceProfanity)} checked={wordspaceProfanity}>
            Wordspace Contains Profanity
          </Checkbox>
          <FormLabel>Difficulty</FormLabel>
          <Select
            id="difficulty"
            variant="outline"
            defaultValue={difficulty}
            onChange={(e) => setDifficulty(e.target.value)}
          >
            <option value="too-easy">Too Easy (No Challenge at All)</option>
            <option value="easy">Easy</option>
            <option value="medium">Medium</option>
            <option value="hard">Hard</option>
            <option value="too-hard">Too Hard (Impossible to Solve)</option>
          </Select>
        </VStack>
        <Button
          marginTop="10px"
          onClick={async () => {
            const numClueId = parseInt(clueId);
            const correctness: Feedback = {
              referenceType: 'clue',
              referenceId: numClueId,
              boardId: '',
              feedbackType: 'correctness',
              value: clueIncorrect ? 'incorrect' : 'correct',
            };

            const profanity: Feedback = {
              referenceType: 'clue',
              referenceId: numClueId,
              boardId: '',
              feedbackType: 'profanity',
              value: clueProfanity.toString(),
            };

            const wordspaceProfanityFeedback: Feedback = {
              referenceType: 'wordspace',
              referenceId: parseInt(wordspaceNumber),
              boardId: '',
              feedbackType: 'profanity',
              value: wordspaceProfanity ? 'profanity' : 'no-profanity',
            };

            const difficultyFeedback: Feedback = {
              referenceType: 'clue',
              referenceId: numClueId,
              boardId: '',
              feedbackType: 'difficulty',
              value: difficulty,
            };

            await apiClient.postFeedback([correctness, profanity, wordspaceProfanityFeedback, difficultyFeedback]);
            setShowModal(false);
            markFeedbackProvided(wordspaceNumber, clueId);
          }}
          type="submit"
        >
          Submit
        </Button>
      </ModalContent>
    </Modal>
  );
};

export default WordspaceFeedbackModal;
