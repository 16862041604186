import { Box, Center, Flex, Text } from '@chakra-ui/react';
import { Wordspace, Clues } from './Interfaces';
import { isMobile } from 'react-device-detect';

interface WordspaceInfoParams {
  selectedWordspace?: Wordspace;
  indexMap?: Record<string, number>;
  clues?: Clues;
}

function WordspaceInfo(params: WordspaceInfoParams) {
  const { selectedWordspace, indexMap, clues } = params;
  const index =
    selectedWordspace && indexMap ? indexMap[`${selectedWordspace.rowNumber}:${selectedWordspace.columnNumber}`] : 0;
  let clue;

  if (selectedWordspace && clues) {
    if (selectedWordspace.orientation === 'across') {
      clues.across.forEach((clueArr: any) => {
        if (clueArr[0] === Number(index)) {
          clue = clueArr[1][0];
        }
      });
    } else {
      clues.down.forEach((clueArr: any) => {
        if (clueArr[0] === Number(index)) {
          clue = clueArr[1][0];
        }
      });
    }
  }

  const content = selectedWordspace ? (
    <Flex p="3" bg="orange" color="white">
      <Center>
        <Box>
          <Text>{index}. {clue}</Text>
        </Box>
      </Center>
    </Flex>
  ) : (
    <p>Click on a cell to select a wordspace</p>
  );
  return content;
}

export default WordspaceInfo;
