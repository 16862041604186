import { useEffect, useState } from 'react';
import { Box, Center, Text } from '@chakra-ui/react';

import { CellParams } from './Interfaces';

function Cell(params: CellParams) {
  const {
    selectedWordspace,
    rowNumber,
    columnNumber,
    char,
    index,
    cursor,
    setCursor,
    orientation,
    setOrientation,
    dimensions,
  } = params;
  const [isCursor, setIsCursor] = useState(false);

  useEffect(() => setIsCursor(cursor[0] === rowNumber && cursor[1] === columnNumber), [cursor]);

  const indexText = (
    <Text as="sup" sx={{ color: index ? 'black' : 'transparent', top: '.1em', left: '.2em' }}>
      {(index && index.toString()) || 'Z'}
    </Text>
  );

  const isInSelectedWordspace = () => {
    if (isCursor) {
      return true;
    }

    if (orientation === 'across') {
      const sameRow = selectedWordspace.rowNumber === rowNumber;
      const withinColumnRange =
        selectedWordspace.columnNumber <= columnNumber &&
        columnNumber < selectedWordspace.columnNumber + selectedWordspace.text.length;

      if (sameRow && withinColumnRange) {
        return true;
      }
    }

    if (orientation === 'down') {
      const sameColumn = selectedWordspace.columnNumber === columnNumber;
      const withinRowRange =
        selectedWordspace.rowNumber <= rowNumber &&
        rowNumber < selectedWordspace.rowNumber + selectedWordspace.text.length;

      if (sameColumn && withinRowRange) {
        return true;
      }
    }
    return false;
  };

  const getBackgroundColor = () => {
    if (char === '*') {
      return 'black';
    }

    if (isCursor) {
      return 'orange';
    }

    if (isInSelectedWordspace()) {
      return 'gray';
    }

    return 'white';
  };

  const displayChar = char === '*' ? '' : char;

  return (
    <Box
      bg={getBackgroundColor()}
      color={'black'}
      width={`${dimensions}px`}
      border="1px solid black"
      height={`${dimensions}px`}
      onClick={() => {
        if (char === '*') {
          return;
        }

        if (isCursor) {
          setOrientation(orientation === 'across' ? 'down' : 'across');
          return;
        }

        setCursor([rowNumber, columnNumber]);
      }}
    >
      {indexText}
      <Center>
        <Text sx={{ fontSize: '40px', position: 'relative', top: '-.5em' }}>{displayChar}</Text>
      </Center>
    </Box>
  );
}

export default Cell;
