import { Box, Flex, Heading, Stack, Skeleton } from '@chakra-ui/react';

import { XwordClue, ClueParams } from './Interfaces';

function CluesDisplay(params: ClueParams) {
  const across = params.clues?.across;
  const down = params.clues?.down;

  const skeletonStack = (
    <Stack>
      <Skeleton height="20px" />
      <Skeleton height="20px" />
      <Skeleton height="20px" />
      <Skeleton height="20px" />
      <Skeleton height="20px" />
      <Skeleton height="20px" />
    </Stack>
  );

  const downBox = (
    <Box width="50%" p="5%">
      <Heading textAlign="center">Down</Heading>
      <div>
        {down
          ? down.map((clue: XwordClue) => (
              <p>
                {clue[0]}: {clue[1][0]}
              </p>
            ))
          : skeletonStack}
      </div>
    </Box>
  );

  const acrossBox = (
    <Box width="50%" p="5%">
      <Heading textAlign="center">Across</Heading>
      <div>
        {across
          ? across.map((clue: XwordClue) => (
              <p>
                {clue[0]}: {clue[1][0]}
              </p>
            ))
          : skeletonStack}
      </div>
    </Box>
  );

  return (
    <Box width="100%" maxWidth="500px">
      <Flex>
        {acrossBox}
        {downBox}
      </Flex>
    </Box>
  );
}

export default CluesDisplay;
