import { Wordspaces, Wordspace } from './Interfaces';

// The width of the screen divided by the width of the board.
// The max screen width will be 500.
export const getCellDimensions = (boardWidth: number): number => {
  const widthPixels: number = window.screen.width > 500 ? 500 : window.screen.width;

  return Math.round(widthPixels / boardWidth);
};

// rowNumber:ColumnNumber -> index
export const getRowColIndexMap = (wordspaces?: Wordspaces) => {
  const map: Record<string, number> = {};

  if (!wordspaces) {
    return map;
  }

  const { across, down } = wordspaces;

  Object.keys(across).forEach((index: any) => {
    const wordspace: Wordspace = across[index] as Wordspace;

    const rowColKey = `${wordspace.rowNumber}:${wordspace.columnNumber}`;
    map[rowColKey] = index;
  });

  Object.keys(down).forEach((index: any) => {
    const wordspace: Wordspace = down[index] as Wordspace;

    const rowColKey = `${wordspace.rowNumber}:${wordspace.columnNumber}`;
    map[rowColKey] = index;
  });

  return map;
};

export const handleArrowKeyNavigation = (
  key: string,
  setCursor: (cursor: [number, number]) => void,
  setOrientation: (orientation: 'across' | 'down') => void,
  orientation: 'across' | 'down',
  cursor: [number, number],
  currentRows: any,
  height: number,
  width: number,
) => {
  const row = cursor[0];
  const col = cursor[1];
  let newRow;
  let newCol;
  let newCursorValue;

  switch (key) {
    case 'UP':
      if (orientation === 'across') {
        setOrientation('down');
        return;
      }

      if (row === 0) return;

      newRow = row - 1;

      newCursorValue = currentRows[newRow][col];
      if (newCursorValue === '*') return;

      setCursor([row - 1, col]);
      break;
    case 'DOWN':
      if (orientation === 'across') {
        setOrientation('down');
        return;
      }

      if (row === height - 1) return;

      newRow = row + 1;

      newCursorValue = currentRows[newRow][col];
      if (newCursorValue === '*') return;

      setCursor([row + 1, col]);
      setOrientation('down');
      break;
    case 'LEFT':
      if (orientation === 'down') {
        setOrientation('across');
        return;
      }

      if (col === 0) return;

      newCol = col - 1;
      newCursorValue = currentRows[row][newCol];
      if (newCursorValue === '*') return;

      setCursor([row, col - 1]);
      setOrientation('across');
      break;
    case 'RIGHT':
      if (orientation === 'down') {
        setOrientation('across');
        return;
      }

      if (col === width - 1) return;

      newCol = col + 1;
      newCursorValue = currentRows[row][newCol];
      if (newCursorValue === '*') return;

      setCursor([row, col + 1]);
      setOrientation('across');
      break;
  }
};
