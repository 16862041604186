import React, { useEffect, useState, useRef } from 'react';
import ReactGA from "react-ga4";
import { useHotkeys } from 'react-hotkeys-hook';
import {
  Center,
  Button,
  Heading,
  Flex,
  Modal,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  IconButton,
  Box,
  Spacer,
  ChakraProvider,
  Text,
  Spinner,
  VStack,
} from '@chakra-ui/react';
import { CloseIcon, RepeatIcon, ViewIcon, InfoOutlineIcon } from '@chakra-ui/icons';
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import { isMobile } from 'react-device-detect';

import { Clues, Wordspaces, Wordspace, GameStates } from './Interfaces';
import Board from './Board';
import CluesDisplay from './CluesDisplay';
import FeedbackModal from './FeedbackModal';
import WordspaceInfo from './WordspaceInfo';
import { apiClient } from './apiClient';
import { getCellDimensions, getRowColIndexMap, handleArrowKeyNavigation } from './utils';

function App() {
  // Initialize Google Analytics
  useEffect(() => {
    ReactGA.initialize('G-J4GP5W1DVM');
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
  }, []);

  const pollUuid = useRef();
  const [uuid, setUuid] = useState<string | null>(null);
  const [width, setWidth] = useState<number>(7);
  const [height, setHeight] = useState<number>(7);
  const [currentRows, setCurrentRows] = useState<string[][]>([]);
  const [title, setTitle] = useState<string>('');
  const [solutionRows, setSolutionRows] = useState<string[][]>([]);
  const [clues, setClues] = useState<Clues>();
  const [wordspaces, setWordspaces] = useState<Wordspaces>();
  const [cursor, setCursor] = useState<[number, number]>([0, 0]);
  const [cursorValue, setCursorValue] = useState<string>('');
  const [orientation, setOrientation] = useState<'across' | 'down'>('across');
  const [selectedWordspace, setSelectedWordspace] = useState<Wordspace>();
  const [gameState, setGameState] = useState<GameStates>('loading');
  const [showModal, setShowModal] = useState<boolean>(true);
  const [showInfoModal, setShowInfoModal] = useState<boolean>(false);
  const [showFeedbackModal, setShowFeedbackModal] = useState<boolean>(false);
  const [nextXwordAt, setNextXwordAt] = useState<string>('');

  /*
   * HOTKEY HANDLING FOR HARDWARE KEYBOARDS ON NON-MOBILE
   */
  useHotkeys(
    [
      'a',
      'b',
      'c',
      'd',
      'e',
      'f',
      'g',
      'h',
      'i',
      'j',
      'k',
      'l',
      'm',
      'n',
      'o',
      'p',
      'q',
      'r',
      's',
      't',
      'u',
      'v',
      'w',
      'x',
      'y',
      'z',
    ],
    (_e: KeyboardEvent, hotkeyEvent: any) => {
      const key = hotkeyEvent.keys[0].toUpperCase();

      const newRows = JSON.parse(JSON.stringify(currentRows));
      const rowNumber = cursor[0];
      const columnNumber = cursor[1];

      newRows[rowNumber][columnNumber] = key;
      setCurrentRows(newRows);
      incrementCursor(cursor);
    },
  );

  /*
   * ARROW KEY HANDLING FOR HARDWARE KEYBOARDS ON NON-MOBILE
   */
  useHotkeys(['up', 'down', 'left', 'right'], (_e: KeyboardEvent, hotkeyEvent: any) => {
    const key = hotkeyEvent.keys[0].toUpperCase();
    handleArrowKeyNavigation(key, setCursor, setOrientation, orientation, cursor, currentRows, height, width);
  });

  useHotkeys(['backspace'], (_e: KeyboardEvent) => {
    const newRows = JSON.parse(JSON.stringify(currentRows));
    const rowNumber = cursor[0];
    const columnNumber = cursor[1];

    newRows[rowNumber][columnNumber] = '';
    setCurrentRows(newRows);
    decrementCursor(cursor);
  });

  /*
   * HOTKEY HANDLING FOR VISUAL KEYBOARD ON MOBILE
   */
  useEffect(() => {
    if (cursorValue.toUpperCase() === '{BKSP}') {
      const newRows = JSON.parse(JSON.stringify(currentRows));
      const rowNumber = cursor[0];
      const columnNumber = cursor[1];

      newRows[rowNumber][columnNumber] = '';
      setCurrentRows(newRows);
      decrementCursor(cursor);
    } else if (cursorValue.match(/^[a-z]{1}$/i)) {
      const key = cursorValue.toUpperCase();

      const newRows = JSON.parse(JSON.stringify(currentRows));
      const rowNumber = cursor[0];
      const columnNumber = cursor[1];

      newRows[rowNumber][columnNumber] = key;
      setCurrentRows(newRows);
      incrementCursor(cursor);
    }
    setCursorValue('');
  }, [cursorValue]);

  const decrementCursor = (localCursor: [number, number]): any => {
    if (orientation === 'across') {
      let row = localCursor[0];
      let col = localCursor[1];

      if (col > 0) {
        col--;
      } else if (row > 0) {
        row--;
        col = width - 1;
      } else {
        // if we can increment no further, return early to avoid
        // infinite recursion
        return;
      }

      const newCursor: [number, number] = [row, col];
      const newCursorChar = currentRows[newCursor[0]][newCursor[1]];

      if (newCursorChar === '*') {
        return decrementCursor(newCursor);
      }

      setCursor([row, col]);
    }

    if (orientation === 'down') {
      let row = localCursor[0];
      let col = localCursor[1];

      if (row > 0) {
        row--;
      } else if (col > 0) {
        col--;
        row = height - 1;
      } else {
        // if we can increment no further, return early to avoid
        // infinite recursion
        return;
      }

      const newCursor: [number, number] = [row, col];
      const newCursorChar = currentRows[newCursor[0]][newCursor[1]];

      if (newCursorChar === '*') {
        return decrementCursor(newCursor);
      }

      setCursor([row, col]);
    }
  };

  const incrementCursor = (localCursor: [number, number]): any => {
    if (orientation === 'across') {
      let row = localCursor[0];
      let col = localCursor[1];

      if (col < width - 1) {
        col++;
      } else if (row < height - 1) {
        row++;
        col = 0;
      } else {
        // if we can increment no further, return early to avoid
        // infinite recursion
        return;
      }

      const newCursor: [number, number] = [row, col];
      const newCursorChar = currentRows[newCursor[0]][newCursor[1]];

      if (newCursorChar !== '') {
        return incrementCursor(newCursor);
      }

      setCursor([row, col]);
    }

    if (orientation === 'down') {
      let row = localCursor[0];
      let col = localCursor[1];

      if (row < height - 1) {
        row++;
      } else if (col < width - 1) {
        col++;
        row = 0;
      } else {
        // if we can increment no further, return early to avoid
        // infinite recursion
        return;
      }

      const newCursor: [number, number] = [row, col];
      const newCursorChar = currentRows[newCursor[0]][newCursor[1]];

      if (newCursorChar !== '') {
        return incrementCursor(newCursor);
      }

      setCursor([row, col]);
    }
  };

  const getBlankRows = (filledRows: string[][]) =>
    filledRows.map((row: string[]) => row.map((char: string) => (char === '*' ? '*' : '')));

  const getWordspaceByXY = (xYCoords: [number, number]): Wordspace => {
    if (orientation === 'across' && wordspaces?.across) {
      const indices = Object.keys(wordspaces.across).map((index: string) => Number(index));

      for (let i = 0; i < indices.length; i++) {
        const index = indices[i];
        const wordspace: Wordspace = wordspaces.across[index];

        if (
          xYCoords[0] === wordspace.rowNumber &&
          xYCoords[1] >= wordspace.columnNumber &&
          xYCoords[1] < wordspace.columnNumber + wordspace.text.length
        ) {
          return wordspace;
        }
      }
    }

    if (orientation === 'down' && wordspaces?.down) {
      const indices = Object.keys(wordspaces.down).map((index: string) => Number(index));

      for (let i = 0; i < indices.length; i++) {
        const index = indices[i];
        const wordspace: Wordspace = wordspaces.down[index];

        if (
          xYCoords[1] === wordspace.columnNumber &&
          xYCoords[0] >= wordspace.rowNumber &&
          xYCoords[0] < wordspace.rowNumber + wordspace.text.length
        ) {
          return wordspace;
        }
      }
    }

    return {} as Wordspace;
  };

  const getLatestXword = async () => {
    const latest = await apiClient.getLatestXword();
    const { xwordId, nextXwordAt} = await latest.json();

    const response = await apiClient.getXword(xwordId);
    const body = await response.json();
    const { width, height, rows, clues, wordspaces, title } = body;

    setWidth(width);
    setHeight(height);
    setSolutionRows(rows.map((row: string) => row.split('')));
    setCurrentRows(getBlankRows(rows.map((row: string) => row.split(''))));
    setClues(clues);
    setTitle(title);
    setWordspaces(wordspaces);
    const firstWordspace = wordspaces.across[1];
    setCursor([firstWordspace.rowNumber, firstWordspace.columnNumber]);
    setSelectedWordspace(getWordspaceByXY(cursor));
    setGameState('inProgress');
    setUuid(xwordId);
    setNextXwordAt(nextXwordAt);
    console.log(`[${body.uuid}] target freq: ${body.targetFreq}, average freq: ${body.averageFreq}`);

    return;
  }

  // UPDATE SELECTED WORDSPACE ON CURSOR OR ORIENTATION CHANGE
  useEffect(() => setSelectedWordspace(getWordspaceByXY(cursor)), [cursor, orientation]);

  // CHECK WHETHER GAME IS SOLVED ON EACH CURRENT ROWS CHANGE
  useEffect(() => {
    const current = currentRows.map((row: string[]) => row.join('')).join('');
    const solution = solutionRows.map((row: string[]) => row.join('')).join('');

    if (current === solution && current !== '' && gameState !== 'revealed') {
      setGameState('solved');
    }
  }, [currentRows]);

  useEffect(() => {
    if (gameState === 'solved') {
      ReactGA.send({ hitType: 'solved', page: window.location.pathname });
      setShowModal(true);
    } else if (gameState === 'loading') {
      getLatestXword();
    } else if (gameState === 'inProgress') {
      setShowModal(false);
    }
  }, [gameState]);

  const getModalContent = (state: GameStates) => {
    switch (state) {
      case 'loading':
        return (
          <>
            <ModalHeader textAlign="center">Loading...</ModalHeader>
            <Center>
              <Spinner />
            </Center>
          </>
        );
      case 'revealed':
        return (
          <>
            <ModalHeader textAlign="center">Clear and Solve Again</ModalHeader>
            {nextInMessage()}
            <Button
              marginTop="30px"
              onClick={async () => {
                setGameState('loading');
                setClues(undefined);
                setUuid(null);
                pollUuid.current = undefined;
                getLatestXword();
              }}
            >
              Reset Board
            </Button>
            <Button
              marginTop="10px"
              onClick={async () => {
                setShowModal(false);
                setShowFeedbackModal(true);
              }}
            >
              Provide Feedback
            </Button>
          </>
        );
      case 'solved':
        return (
          <>
            <ModalHeader textAlign="center">Congratulations!</ModalHeader>
            <Center><p>You solved it.</p></Center>
            {nextInMessage()}
            <Text textAlign="center">Would you like to play this Xword again?</Text>
            <Button
              marginTop="30px"
              onClick={async () => {
                setGameState('loading');
                setClues(undefined);
                getLatestXword();
              }}
            >
              Clear and Play Again
            </Button>
            <Button
              marginTop="10px"
              onClick={async () => {
                setShowModal(false);
                setShowFeedbackModal(true);
              }}
            >
              Provide Feedback
            </Button>
          </>
        );
      case 'clearConfirm':
        return (
          <>
            <ModalHeader textAlign="center">Clear</ModalHeader>
            <Text textAlign="center">Are you sure you want to clear the board?</Text>
            <Button
              marginTop="30px"
              isDisabled={!uuid}
              onClick={async () => {
                setCurrentRows(getBlankRows(solutionRows));
                setGameState('inProgress');
              }}
            >
              Clear
            </Button>
          </>
        );
      case 'revealConfirm':
        return (
          <>
            <ModalHeader textAlign="center">Reveal</ModalHeader>
            <Text textAlign="center">Are you sure you want to reveal the board?</Text>
            <Button
              marginTop="30px"
              isDisabled={!uuid}
              onClick={async () => {
                ReactGA.send({ hitType: 'revealed', page: window.location.pathname });
                setCurrentRows(solutionRows);
                setGameState('revealed');
              }}
            >
              Reveal
            </Button>
          </>
        );
    }
  };

  const clueDisplayTopMargin = getCellDimensions(width) * height;

  const nextInMessage = () => {
    const timeUntilNextXword = new Date(Number(nextXwordAt) - Date.now());
    return (
      <>
        <Center><p>New Xwords are released twice a day, at noon and midnight GMT.</p></Center>
        <br />
        <Center><p>Next in: <b>{timeUntilNextXword.getUTCHours()}h {timeUntilNextXword.getMinutes()}m</b></p></Center>
        <br />
      </>
    )
  };

  return (
    <ChakraProvider>
        <Box bg="white">
          <Center>
            <Modal preserveScrollBarGap={true} isOpen={showModal} onClose={() => {}}>
              <ModalOverlay bg={gameState === 'loading' ? 'grey' : 'rgba(70,70,70,0.5)'} />;
              <ModalContent maxW="290px" p="20px">
                {gameState !== 'loading' && (
                  <ModalCloseButton onClick={() => setShowModal(false)} />
                )}
                {getModalContent(gameState)}
              </ModalContent>
            </Modal>
            <Modal isOpen={showInfoModal} onClose={() => {}}>
              <ModalOverlay bg={'rgba(70,70,70,0.5)'} />;
              <ModalContent maxW="290px" p="20px">
                <ModalCloseButton onClick={() => setShowInfoModal(false)} />
                <ModalHeader textAlign="center">{title || "XWord"}: {uuid?.split('-')[4]}</ModalHeader>
                {nextInMessage()}
              </ModalContent>
            </Modal>
            {wordspaces && clues && (
              <FeedbackModal
                wordspaces={wordspaces}
                clues={clues}
                showModal={showFeedbackModal}
                setShowModal={setShowFeedbackModal}
                setShowOuterModal={setShowModal}
              />
            )}
            <Box left={isMobile ? '0' : undefined} bg="white">
              <Flex>
                <Box paddingTop="4" paddingLeft="2" bg="white">
                  <Heading>{title || "XWord"}</Heading>
                </Box>
                <Spacer />
                <Box p="4" bg="white">
                  {gameState === 'solved' || gameState === 'revealed' || gameState === 'clearConfirm' ? (
                    <IconButton
                      isDisabled={!uuid}
                      onClick={async () => {
                        setShowModal(true);
                      }}
                      icon={<RepeatIcon />}
                      aria-label="Generate"
                    >
                      Generate
                    </IconButton>
                  ) : (
                    <IconButton
                      isDisabled={!uuid}
                      onClick={async () => {
                        setGameState('revealConfirm');
                        setShowModal(true);
                      }}
                      icon={<ViewIcon />}
                      aria-label="Reveal"
                    >
                      Reveal
                    </IconButton>
                  )}
                  <IconButton
                    isDisabled={!uuid}
                    aria-label="Clear"
                    icon={<CloseIcon />}
                    onClick={async () => {
                      setGameState('clearConfirm');
                      setShowModal(true);
                    }}
                  >
                    Clear
                  </IconButton>
                  <IconButton
                    isDisabled={!uuid}
                    aria-label="Info"
                    icon={<InfoOutlineIcon />}
                    onClick={async () => setShowInfoModal(true)}
                  >
                    Clear
                  </IconButton>
                </Box>
              </Flex>
              <VStack spacing="0">
                <Board
                  currentRows={currentRows}
                  setCurrentRows={setCurrentRows}
                  width={width}
                  height={height}
                  wordspaces={wordspaces}
                  cursor={cursor}
                  setCursor={setCursor}
                  setCursorValue={setCursorValue}
                  orientation={orientation}
                  setOrientation={setOrientation}
                  selectedWordspace={selectedWordspace as Wordspace}
                />
                  <Box
                    width={clueDisplayTopMargin}
                    onClick={() => setOrientation(orientation === 'across' ? 'down' : 'across')}
                  >
                    <WordspaceInfo
                      selectedWordspace={selectedWordspace}
                      indexMap={getRowColIndexMap(wordspaces)}
                      clues={clues}
                    />
                </Box>
                {!isMobile && <CluesDisplay clues={clues} />}
                {isMobile && (
                    <Keyboard
                      onKeyPress={(key: string) => {
                        setCursorValue(key);
                      }}
                      layout={{
                        default: ['Q W E R T Y U I O P', 'A S D F G H J K L', 'Z X C V B N M {bksp}'],
                      }}
                    />
                )}
              </VStack>
            </Box>
          </Center>
        </Box>
    </ChakraProvider>
  );
}

export default App;
