import { Box, Flex } from '@chakra-ui/react';

import { BoardParams } from './Interfaces';
import Cell from './Cell';
import { getCellDimensions, getRowColIndexMap } from './utils';

function Board(params: BoardParams) {
  const {
    currentRows,
    width,
    wordspaces,
    orientation,
    setOrientation,
    cursor,
    setCursor,
    setCursorValue,
    selectedWordspace,
  } = params;

  // rowNumber:ColumnNumber -> index
  const rowColIndexMap = getRowColIndexMap(wordspaces);

  const rowCells = currentRows.map((row: string[], rowNumber: number) => {
    return (
      <Flex>
        {row.map((char: string, columnNumber: number) => {
          const rowColKey = `${rowNumber}:${columnNumber}`;
          const index = rowColIndexMap[rowColKey];
          let cellDimensions = getCellDimensions(width);

          return (
            <Cell
              rowNumber={rowNumber}
              columnNumber={columnNumber}
              cursor={cursor}
              setCursor={setCursor}
              setCursorValue={setCursorValue}
              char={char}
              setChar={() => {}}
              index={index}
              orientation={orientation}
              setOrientation={setOrientation}
              selectedWordspace={selectedWordspace}
              dimensions={cellDimensions}
            />
          );
        })}
      </Flex>
    );
  });

  return <Box maxWidth="500px">{rowCells}</Box>;
}

export default Board;
