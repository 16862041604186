import React, { useState } from 'react';
import {
  Center,
  Button,
  Heading,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  Text,
  VStack,
} from '@chakra-ui/react';
import WordspaceFeedbackModal from './WordspaceFeedbackModal';
import FeedbackButton from './FeedbackButton';

interface FeedbackModalParams {
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  setShowOuterModal: (showModal: boolean) => void;
  wordspaces: any;
  clues: any;
}

const FeedbackModal = ({ showModal, wordspaces, clues, setShowModal, setShowOuterModal }: FeedbackModalParams) => {
  const [feedbackProvidedMap, setFeedbackMap] = useState<Record<string, boolean>>({});
  const [wordspaceNumber, setWordspaceNumber] = useState('');
  const [plaintext, setPlaintext] = useState('');
  const [clueText, setClueText] = useState('');
  const [clueId, setClueId] = useState('');
  const [showWordspaceFeedbackModal, setShowWordspaceFeedbackModal] = useState(false);

  const acrossIndices = Object.keys(wordspaces.across);
  const downIndices = Object.keys(wordspaces.down);

  const markFeedbackProvided = (wordspaceNumber: string, clueId: string) => {
    const newFeedbackProvidedMap: Record<string, boolean> = { ...feedbackProvidedMap };
    newFeedbackProvidedMap[`${wordspaceNumber}-${clueId}`] = true;
    setFeedbackMap(newFeedbackProvidedMap);
  };

  return (
    <Modal isOpen={showModal} onClose={() => setShowModal(false)} scrollBehavior={'outside'}>
      <ModalOverlay bg="grey" />;
      <ModalContent maxW="450px" p="20px">
        <ModalCloseButton
          onClick={() => {
            setShowModal(false);
            setShowOuterModal(true);
          }}
        />
        <Center>
          <Heading fontSize="40px" fontWeight="bold" color="black" sx={{ marginBottom: '10px' }}>
            Provide Feedback
          </Heading>
        </Center>
        <Text fontSize="14px" color="black" sx={{ marginBottom: '10px' }}>
          Click any of the items below to provide feedback on word or clue.
        </Text>
        <Center>
          <Heading fontSize="20px" fontWeight="bold" color="black">
            Down
          </Heading>
        </Center>
        <VStack spacing="10px" mt="20px">
          {downIndices.map((wordspaceNumber, index) => {
            const plaintext = wordspaces.down[wordspaceNumber].text;
            const clueText = clues.down[index][1][0];
            const clueId = clues.down[index][1][1];
            const disabled = feedbackProvidedMap[`${wordspaceNumber}-${clueId}`];

            const handleClick = () => {
              setWordspaceNumber(wordspaceNumber);
              setPlaintext(plaintext);
              setClueText(clueText);
              setClueId(clueId);
              setShowModal(true);
              setShowWordspaceFeedbackModal(true);
            };

            return (
              <FeedbackButton
                index={index}
                wordspaceNumber={wordspaceNumber}
                orientation={'down'}
                disabled={disabled}
                handleClick={handleClick}
                plaintext={plaintext}
                clue={clueText}
              />
            );
          })}
        </VStack>
        <Center>
          <Heading fontSize="20px" fontWeight="bold" color="black">
            Across
          </Heading>
        </Center>
        <VStack spacing="10px" mt="20px">
          {acrossIndices.map((wordspaceNumber, index) => {
            const plaintext = wordspaces.across[wordspaceNumber].text;
            const clueText = clues.across[index][1][0];
            const clueId = clues.across[index][1][1];
            const disabled = feedbackProvidedMap[`${wordspaceNumber}-${clueId}`];

            const handleClick = () => {
              setWordspaceNumber(wordspaceNumber);
              setPlaintext(plaintext);
              setClueText(clueText);
              setClueId(clueId);
              setShowModal(true);
              setShowWordspaceFeedbackModal(true);
            };

            return (
              <FeedbackButton
                index={index}
                wordspaceNumber={wordspaceNumber}
                orientation={'down'}
                disabled={disabled}
                handleClick={handleClick}
                plaintext={plaintext}
                clue={clueText}
              />
            );
          })}
        </VStack>
        <Button
          marginTop="10px"
          onClick={async () => {
            // because chakra modals wont scroll for some reason if multiple modals are
            // open we have to reopen the outer (App-level modal) here.
            setShowModal(false);
            setShowOuterModal(true);
          }}
        >
          Done
        </Button>
      </ModalContent>
      <WordspaceFeedbackModal
        wordspaceNumber={wordspaceNumber}
        plaintext={plaintext}
        clueText={clueText}
        clueId={clueId}
        showModal={showWordspaceFeedbackModal}
        setShowModal={setShowWordspaceFeedbackModal}
        markFeedbackProvided={markFeedbackProvided}
      />
    </Modal>
  );
};

export default FeedbackModal;
